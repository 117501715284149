export default {
    "project": {
        "search": "Поиск",
        "private_group:": "Приватная",
        "work_groups": "Команды",
        "work-groups": "Команды",
        "count_task": "Всего задач",
        "about_me": "О нас",
        "hide": "Скрыть",
        "status": "Статус",
        "closed": "Закрытая",
        "closed_group": "Закрытая команда",
        "author_chat": "Написать автору",
        "group_info_hide": "Информация скрыта",
        "group_info_hide_desc": "Для просмотра полной информации вам необходимо вступить",
        "public": "Публичная",
        "date_create": "Дата основания",
        "date_begin": "Время начала",
        "image_not_found": "Нет изображений",
        "add_image": "Добавить изображение",
        "gallery": "Галерея",
        "remove_event": "Удалить событие",
        "events": "События",
        "gant": "Диаграмма Ганта",
        "social_links": "Ссылки на социальные сети",
        "type": "Тип",
        "edit": "Редактировать",
        "news_not_found": "Новостей нет",
        "no_partisipants_group": "Вы не являетесь участником закрытой команды",
        "invite_participants": "Пригласить участника",
        "participants_count": "Количество участников",
        "participants": "Участники",
        "add_participants": "Добавить участников",
        "create_group": "Добавить команду",
        "update_group": "Редактировать команду",
        "title_group": "Название команды",
        "description": "Описание",
        "group_type": "Тип команды",
        "add_soc_link": "Добавить соц сеть",
        "avatar_group": "Аватар",
        "avatar_project": "Аватар проекта",
        "create": "Создать",
        "upload_file": "Загрузить файл",
        "create_post": "Создать публикацию",
        "edit_post": "Редактировать публикацию",
        "title_news": "Заголовок новости",
        "image": "Картинка",
        "type_soc_link": "Тип социальной сети",
        "place": "Место",
        "invite": "Пригласить",
        "make_appointment": "Записаться на прием",
        "more": "Подробнее",
        "participantss": "участников",
        "director": "Основатель",
        "tasks_complete": "Завершенных задач",
        "no_data": "Нет данных",
        "today_no_events": "Сегодня событий нет",
        "link": "Ссылка",
        "information_edited": "Информация изменена",
        "group_created": "Команда успешно создана",
        "fill_all_fields": "Заполните все поля",
        "invalid_link": "Неверная ссылка",
        "join_group": "Вступить в команду",
        "invite_group": "Пригласить в команду",
        "cancel": "Отмена",
        "fill_FIO": "Введите ФИО",
        "news": "Новости",
        "event": "Событие",
        "task": "Задачи",
        "role": "Роль",
        "group": "Команда",
        "open": "Открыть",
        "projects": "Проекты",
        "project": "Проект",
        "add_project": "Добавить проект",
        "create_project": "Создать проект",
        "update_project": "Редактировать проект",
        "project_not": "Проектов нет",
        "group_not": "Команды нет",
        "title_project": "Название проекта",
        "deadline_project": "Срок сдачи проекта",
        "deadline_project2": "Срок сдачи",
        "date_start_plan": "Дата начала",
        "finished_project": "Завершить проект",
        "resume_project": "Продолжить проект",
        "time": "Время",
        "time_is_required": "Нужно указать время",
        "group-and-project": "Команды и проекты",
        "group&project": "Команды и проекты",
        "workgroups": "Команды",
        "project_created": "Проект создан",
        "project_finished": "Проект завершен",
        "enter_the_conference": "Войти в конференцию",
        "meetings": "Конференция",
        "invite_link": "Ссылка для приглашения",
        "participant": "участник",
        "participant2": "участника",
        "participant3": "участников",
        "event_name": "Название события",
        "periud_time": "Период времени",
        "you_partisipants": "Вы участник команды",
        "you_not_member_group": "Вы больше не являетесь участником команды",
        "successful": "Успешно",
        "news_created": "Новость создана",
        "member_delete": "Участник удален",
        "date_validation_min_max": "Дата начала должна быть не раньше даты окончания",
        "event_created": "Событие создано",
        "member_is_moderator": "Участник назначен модератором",
        "exit": "Выйти",
        "load_more": "Загрузить еще",
        "error": "Ошибка",
        "update": "Сохранить",
        "delete_image": "Удалить изображение",
        "yes": "Да",
        "no": "Нет",
        "field_require": "Обязательно для заполнения",
        "with_chat": "Привязать чат",
        "create_chat": "Создать чат",
        "open_chat": "Открыть чат",
        "comments": "Комментарии",
        "is_author": "Вы автор",
        "author": "Автор",
        "select": "Выбрать",
        "save": "Сохранить",
        "remove_partisipant": "Удалить участника",
        "change_moderator": "Назначить модератором",
        "users_not_fount": "Пользователей нет",
        "calendar": "Календарь",
        "task_calendar": "Задача",
        "event_date": "Дата события",
        "kanban": "Канбан",
        "table": "Список",
        "warning": "Предупреждение",
        "delete_confirm_text": "Вы действительно хотите удалить {type}?",
        "project_label": "проект",
        "group_label": "команду",
        "close": "Закрыть",
        "delete": "Удалить",
        "project_delete": "Проект удален",
        "group_delete": "Команда удалена",
        "counterparty": "Контрагент",
        "select_counterparty": "Выбрать контрагента",
        "program": "Программа",
        "select_program": "Выбрать программу",
        "costing_object": "Объект калькуляции",
        "select_costing_object": "Выбрать объект калькуляции",
        "change": "Изменить",
        "analytics": "Статистика",
        "stat_empty": "На данный момент недостаточно данных для статистики",
        "sprints": "Спринты",
        "sprint": "Спринт",
        "share": "Поделиться",
        "all_budget": "Общая смета",
        "all_difficulty": "Средняя оценка сложности",
        "files": "Файлы",
        "interest": "Интересы",
        "control_dates": "Вести контроль дат задач",
        "group_files": "Файлы",
        "chat_files": "Файлы чата",
        "select_organization": "Выбрать организацию",
        "organization": "Организация",
        "organization_name": "Название организации",
        "project_control": "При выборе опции ведется контроль временных границ подзадач в пределах задачи и задач в пределах проекта",
        "logo_upload": "Загрузить логотип",
        "upload": "Загрузить",
        "no_date": "Нет даты",
        "chat_created": "Чат успешно создан!",
        "not_found": "Группа/Проект не найден или удален",
        "group_share": "Группа",
        "add_task": "Добавить задачу",
        "control_on": "Контроль дат включен",
        "moderator": "Модератор",
        "at_files": "Прикрепленные файлы",
        "amount": "Общая сумма:",
        "all_task": "Все задачи",
        "new": "Новые",
        "work": "В работе",
        "pause": "На паузе",
        "review": "На проверке",
        "rework": "На переделке",
        "on_performance": "На исполнении",
        "done": "Выполнено",
        "overall_rating": "Общая оценка:",
        "my_task": "Мои задачи",
        "templates": "Шаблоны",
        "project_templates": "Шаблоны проектов",
        "create_template": "Создать шаблон",
        "stage_name": "Название этапа",
        "stage_created": "Этап создан",
        "template_saved_successfully": "Этап успешно сохранен",
        "delete_template?": "Удалить шаблон?",
        "create_stage": "Добавить этап",
        "select_project_template": "Выберите шаблон проекта",
        "new_project": "Новый проект",
        "could_not_create_a_template": "Не удалось создать шаблон",
        "could_not_save_a_template": "Не удалось сохранить шаблон",
        "could_not_load_templates": "Не удалось загрузить шаблоны",
        "enter_the_template_name": "Введите имя шаблона",
        "please_fill_in_all_required_fields": "Пожалуйста, заполните все необходимые поля",
        "the_organization_using_the_template": "Организация использующая шаблон",
        "save_template": "Сохранить шаблон",
        "completed": "Завершены",
        "project_list": "Список проектов",
        "project_gant": "Диаграмма Ганта",
        
    }
}