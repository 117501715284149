export default {
    sports: {
        sportsFacilities: "Реестр спортивных объектов",
        add: "Добавить паспорт объекта",
        addTitle: "Добавить паспорт объекта",
        editTitle: "Редактировать паспорт объекта",
        filterTitle: "Фильтровать по статусу",
        open: "Открыть",
        more: "Подробнее",
        repair: "Информация о ремонте",
        sections: "Информация о секциях/кружках",
        technical: "Материально-техническое оснащение",
        generateStat: "Сформировать отчет",
        noProjects: "Нет данных",
        "objectPassport": "Паспорт объекта",
        "organization": "Организация",
        "responsible": "Ответственный",
        "tabInformation": "Информация",
        "tabDocuments": "Документы",
        "tabChangeHistory": "История изменений",
        "projectMainInfo": "Основная информация о проекте",
        "repairNeed": "Потребность в ремонте",
        "equipmentNeed": "Требуется материально-техническое оснащение",
        "location": "Местоположение",
        "ownershipType": "Форма собственности",
        "government": "Государственная",
        "ownerOrganization": "Наименование организации собственника",
        "bin": "БИН",
        "purpose": "Назначение",
        "sportsFacility": "Объект физкультурно-спортивного назначения",
        "sportsFacilityType": "Вид спортивного сооружения",
        "sportsHall": "Спортивный зал",
        "sportsFacilitySubtype": "Подвид спортивного сооружения",
        "educationalInstitutions": "В общеобразовательных учреждениях, СУЗ",
        "constructionYear": "Год постройки",
        "capacity": "Пропускная способность",
        "storeys": "Этажность",
        "area": "Площадь",
        owner: "Собственник",
        r_date: "Дата последнего ремонта",
        r_type: "Тип ремонта",
        r_sum: "Стоимость ремонта",
        status: "Статус",
        formError: "Обязательно для заполнения",
        "repairRequest": "Заявка на ремонт",
        "needRepair": "Существует потребность в ремонте",
        "yes": "Да",
        "no": "Нет",
        "repairCost": "Стоимость ремонта, млн тенге",
        "enterCost": "Введите стоимость",
        "comment": "Комментарий",
        "enterComment": "Введите комментарий",
        "attachFiles": "Прикрепить файлы",
        "toReview": "На проверку",
        "saveChanges": "Сохранить изменения",
        "repairInfo": "Информация о ремонте",
        "repairDate": "Дата ремонта",
        "selectDate": "Выбрать дату",
        "repairType": "Вид ремонта",
        "selectFromList": "Выберите из списка",
        "addRepairInfo": "Добавить информацию о ремонте",
        "equipmentList": "Перечень оборудования",
        "cost": "Стоимость",
        "quantity": "Кол-во",
        "unitPrice": "Цена за ед.",
        "purchaseDate": "Дата приобретения",
        "serviceLife": "Срок службы",
        "condition": "Состояние",
        "files": "Файлы",
        "gallery": "Галерея",
        "noHistory": "Нет истории",
        "changeAuthor": "Автор изменений:",
        "full_sports_facilities_repair": "Информация",
        "full_sports_facilities_files": "Документы",
        "full_sports_facilities_history": "История",
        "back_route": "Вернуться назад",
        "edit": "Редактировать",
        "delete": "Удалить",
        "delete_message": "Вы действительно хотите удалить паспорт объекта?",
        "cancel": "Отмена",
        "delete_success": "Паспорт объекта успешно удален",
        "all_object": "Всего объектов",
        "repair_price": "Общая стоимость ремонта на текущий год",
        "all_sport_object": "Всего видов спортивных сооружений",
        "address_select": "Выбрать адрес",
        "name": "Наименование",
        "lat": "Широта",
        "lng": "Долгота",
        "save": "Сохранить",
        "map_search": "Введите адрес объекта",
        "map_select_point": "Выберите точку на карте или введите адрес в поиске",
        "category": "Категория",
        "subcategory": "Подкатегория",
        "sport_type": "Вид спорта",
        "message_compliance": "Соответствие объекта для проведения республиканских соревнований",
        "object_pasport_updated": "Паспорт объекта успешно обновлен",
        "object_pasport_created": "Паспорт объекта успешно создан",
        "field_empty": "Заполните обязательные поля",
        "object_name": "Наименование паспорта объекта",
        "cult_sports": "Культивируемые виды спорта по проекту",
        "countryside": "Сельская местность",
        "region": "Область / Город республиканского значения",
        "district": "Район / Город республиканского значения",
        "akimat": "Акимат",
        "settlement": "Поселение",
        "village": "Село",
        "provide_address": "Укажите адрес",
        "org_name": "Наименование организации-собственника",
        "o_type": "Вид образовательного учреждения",
        "sport_school_type": "Вид спортивной школы",
        "area2": "Площадь, м2",
        "capacity2": "Пропускная способность, чел",
        "create_object": "Создать объект",
        "unknown_error": "Неизвестная ошибка",
        "characteristics": "Характеристика объекта",
        "full_sports_facilities_gallery": "Галерея",
        "work_types_card": "Виды выполненных работ",
        "addOnly": "Добавить",
        "close": "Закрыть",
        "repairDeleteMessage": "Вы действительно хотите удалить информацию о ремонте?",
        "deletedError": "Ошибка удаления",
        "repairDeleted": "Информацию о ремонт удалена",
        "amount": "Стоимость",
        "map_view": "Карта спортивных объектов",
        "not_specified": "Не указана",
        "downloadReport": "Скачать отчет",
        "reportFileName": "Реестр спортивных объектов, отчет от {date}",
        "objectInformation": "Помещения и сооружения",
        "addObject": "Добавить объект",
        "objectsInfo": "Информация по объектам",
        "objectName": "Наименование",
        "purpose": "Назначение",
        "roomType": "Тип помещения",
        "purposeObject": "Назначение помещения",
        "nameObject": "Наименование помещения",
        "objectCharacteristics": "Характеристики по назначению помещения",
        "notWidget": "Нет виджета",
        "objectInfoCreated": "Помещение успешно создано",
        "objectInfoUpdated": "Помещение успешно сохранено",
        "error": "Ошибка",
        "objectInfoAlert": "Выберите назначение помещения и тип помещения",
        "addObject": "Добавить помещение",
        "objectDelete": "Вы действительно хотите удалить помещение?",
        "objectDeleteSuccess": "Помещение успешно удалено",
        "sectionInfo": "Информация о секциях/кружках",
        "sectionCount": "Количество секций по виду спорта, единиц",
        "sectionCount2": "Численность занимающихся в секциях по виду спорта, человек",
        "sectionCount3": "Численность тренеров, тренеров - преподавателей, человек",
        "full_sports_facilities_object_information": "Помещения и сооружения",
        "expand": "Развернуть",
        "collapse": "Свернуть",
        "sportTypeValidate": "Необходимо выбрать категорию и вид спорта",
        "sectionAdd": "Добавить секцию/кружок",
        "sectionAddSuccess": "Секция/кружок создана",
        "full_sports_facilities_section_information": "Информация о секциях/кружках",
        "sectionEditSuccess": "Информация о количестве секций по виду спорта успешно сохранена",
        "peopleCol1": "Занимающихся переменного состава",
        "peopleCol2": "Занимающихся постоянного состава",
        "peopleCol3": "Всего",
        "peopleCol4": "Лиц женского пола",
        "peopleCol5": "До 17 лет",
        "peopleCol6": "В возрасте 18-20",
        "peopleCol7": "Количество спортсменов первого спортивного разряда",
        "peopleCol8": "Количество кандидатов в мастера спорта",
        "peopleCol9": "Количество мастеров спорта",
        "peopleEditSuccess": "Информация о численности занимающихся в секциях по виду спорта успешно сохранена",
        "totalCol": "Итого",
        "female": "Женщин",
        "coachesCol1": "Имеют образование по специальности «Физическая культура и спорт»",
        "higherEducation": "Высшее",
        "middleEducation": "Средне специальное",
        "coachesEditSuccess": "Информация о численности тренеров, тренеров - преподавателей успешно сохранена",
        "sectionDelete": "Вы действительно хотите удалить секцию/кружок?",
        "sectionDeleteSuccess": "Секцию/кружок успешно удалена",
        "pressNumber": "Введите число",
        "formMainInfo": "Основная информация об объекте",
        "formLocation": "Местоположение объекта",
        "formObjectInfo": "Сведения о собственнике объекта",
        "formOtherInfo": "Дополнительная информация",
        "heating_type": "Система отопления",
        "staff_quantity": "Численность обслуживающего персонала",
        "hasLabel": "Доступность для лиц с ограниченными возможностями",
        "has_ramp": "Наличие пандуса",
        "has_access_to_all_floors": "Наличие доступности на все этажи сооружения",
        "has_equipped_bathrooms": "Наличие оборудованных санузлов",
        "has_access_elevator": "Доступность лифтов",
        "mapCount": "Всего",
        "mapDistrict": "Районы",
        "mapRegion": "Области",
        "setFilter": "Применить фильт",
        "clearFilter": "Очистить фильтр",
        "mapLayout": "Переключить слой карты",
        "disabledMapLayout": "Отключить области и районы",
        "mapFullscreen": "Развернуть на весь экран",
        "mapAddress": "Адрес",
        "mapOpen": "Открыть",
        "mapZoomIn": "Приблизить",
        "mapFilter": "Фильтр",
        "un": "ед.",
        "tg": "тенге",
        "repairStat": "Статистика по реестру объектов",
        "not_specified2": "Не указан"
    }
}