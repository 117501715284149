export default {
    "task": {
        "auction": "Аукцион",
        "new": "Новая",
        "in_work": "В работе",
        "on_pause": "На паузе",
        "on_check": "На проверке",
        "on_rework": "На переделке",
        "completed": "Завершена",
        "to_work": "Начать выполнение",
        "to_pause": "Приостановить",
        "to_check": "На проверку",
        "to_rework": "Переделать",
        "to_completed": "Завершить",
        "to_new": "Новая",
        "resume": "Возобновить",
        "today": "Сегодня",
        "task": "Задача",
        "interest": "Интерес",
        "task2": "Задача",
        "history": "История",
        "created": "Создана",
        "dead_line": "Крайний срок",
        "priority": "Приоритет",
        "contractor": "Клиент",
        "organization": "Организация",
        "potential_contractor": "Лид",
        "potential_contractor_client": "Клиент",
        "potential_contractor_region_name": "Бизнес регион",
        "potential_contractor_company_name": "Организация",
        "operator": "Ответственный",
        "visor": "Наблюдатель",
        "project": "Проект",
        "workgroup": "Рабочая группа",
        "add_subtask": "Добавить подзадачу",
        "share_to_chat": "Поделиться",
        "copy": "Скопировать",
        "edit": "Редактировать",
        "remove": "Удалить",
        "comments": "Комментарии",
        "main_task": "Основная задача:",
        "main_task_message": "Задача на основе сообщения в чате:",
        "main_task_comment": "Задача на основе комментария:",
        "main_task_files": "Задача на основе файла:",
        "main_task_order":"Задача на основе заказа:",
        "add": "Добавить",
        "no_description": "Описание отсутствует",
        "description": "Описание",
        "task_deleted": "Задача удалена",
        "error": "Ошибка",
        "task_not_found": "Такой задачи не существует либо она была удалена",
        "ultralow": "Очень низкий",
        "low": "Низкий",
        "middle": "Средний",
        "tall": "Высокий",
        "veryhigh": "Очень высокий",
        "1": "Низкий",
        "2": "Средний",
        "3": "Высокий",
        "owner": "Постановщик",
        "field_require_all": "Заполните обязательные поля",
        "field_require": "Обязательно для заполнения",
        "field_min_require": "Минимум {min} символов",
        "task_created": "Задача создана",
        "task_updated": "Задача обновлена",
        "edit_task": "Редактировать задачу",
        "add_task": "Добавить задачу",
        "add_milestone": "Добавить веху",
        "task_based": "Задача на основе",
        "task_name": "Название задачи",
        "field_max_require": "Максимум {max} символов",
        "task_desc": "Описание задачи",
        "task_duration": "Продолжительность задачи",
        "select_performer": "Выбрать исполнителя",
        "select_author": "Выбрать автора",
        "observers": "Наблюдатели",
        "select_observers": "Выбрать наблюдателей",
        "chat_message": "Сообщения в чате",
        "comment": "Комментария",
        "file2": "Файла",
        "order": "Заказ",
        "projects": "Проекты",
        "additionally": "Дополнительно",
        "additionally_fields": "Доп. поля",
        "make_it_subtask": "Сделать подзадачей",
        "make_it_subissue": "Участник обращения",
        "subtask": "Подзадачи",
        "change": "Изменить",
        "select": "Выбрать",
        "files": "Файлы",
        "save": "Сохранить",
        "create_and_open": "Сохранить и открыть",
        "create_and_create": "Сохранить и создать еще одну",
        "create_and_copy": "Сохранить и скопировать",
        "close": "Закрыть",
        "select_task": "Выбрать задачу",
        "no_time_limit": "Без срока",
        "user_name": "Имя пользователя",
        "select_user": "Выбрать пользователя",
        "project_name": "Название проекта",
        "select_project": "Выбрать проект",
        "working_group_name": "Название рабочей группы",
        "select_working_group": "Выбрать рабочую группу",
        "select_date": "Выбрать дату",
        "task-list-page": "Задачи",
        "task-calendar-page": "Календарь",
        "task-kanban-page": "Канбан",
        "task-gantt-page": "Диаграмма Ганта",
        "task-sprint-page": "Спринты",
        "name": "Название",
        "status": "Статус",
        "actions": "Действия",
        "no_data": "Нет данных",
        "load_more": "Загрузить еще",
        "create_fast_task": "Создать быструю задачу",
        "tasks_not_deadline": "Задачи без дедлайна",
        "week": "Неделя",
        "month": "Месяц",
        "quarter": "Квартал",
        "year": "Год",
        "day": "День",
        "action_disabled": "Действие запрещено",
        "large_priority": "Высокий приоритет",
        "very_large_priority": "Самый высокий приоритет",
        "small_priority": "Низкий приоритет",
        "very_low_priority": "Очень низкий приоритет",
        "accounting": "Учет",
        "participant_sprint": "Участник спринта",
        "user_not_owner": "Пользователь не является владельцем задачи.",
        "author": "Автор",
        "date_start": "Планируемая дата выезда",
        "date_start_fact": "Фактическая дата выезда",
        "date_start_plan": "Дата начала",
        "date_change": "Крайний срок|Дата начала изменены под родительскую задачу",
        "task_phone": "Телефон",
        "subject_matter": "Тематика",
        "add_appeal": "Добавить обращение",
        "edit_appeal": "Редактировать обращение",
        "appeal_name": "Название обращения",
        "open_task": "Открыть задачу",
        "email": "Электронная почта",
        "organization_name": "Название организации",
        "make_event": "Создать событие",
        "excel_download": "Скачать в Excel",
        "loading": "Загрузка",
        "statistics_from": "статистика от",
        "file_is_generated": "Файл сформирован",
        "loading_error": "Ошибка загрузки",
        "contractordrawer": {
            "choose": "Выбрать",
            "drawerTitle": "Выбрать клиента",
            "searchPlaceholder": "Имя клиента"
        },
        "change_person": "Сменить ответственного",
        "task_stat": "Статистика по задаче",
        "stat_not_found": "Аналитика недоступна",
        "general_estimate": "Общая смета",
        "total_sum": "Общая сумма:",
        "difficulty_rating": "Оценка сложности",
        "overall_rating": "Общая оценка:",
        "draft": "Черновик",
        "source_appeal": "Источник обращения",
        "upload_file": "Загрузить файл",
        "specify_address": "Указать адрес",
        "specify_address_2": "Указать адреса",
        "delivery_from": "Доставка от",
        "task_from": "Задача от",
        "small_name": "Краткое имя",
        "latitude": "Широта",
        "longitude": "Долгота",
        "address": "Адрес",
        "add_address": "Добавить адрес",
        "clear": "Очистить",
        "point_delete": "Вы действительно хотите удалить точку?",
        "yes": "Да",
        "no": "Нет",
        "address_deleted": "Адрес удален",
        "form_field_required": "Заполните все поля формы!",
        "handler": {
            "confirmTakeTask": "Вы действительно хотите взять задачу?",
            "cancel": "Отмена",
            "ok": "Взять",
            "success": "Задача Ваша",
            "error": "Произошла ошибка"
        },
        "file_share_error": "Не удалось поделиться файлом",
        "mobile_sort": {
            "searchPlaceholder": "Поиск",
            "descendingOrder": "По убыванию номера",
            "ascendingOrder": "По нарастанию номера",
            "byOperator": "По ответственному",
            "newItems": "Новинки"
        },
        "task_empty": "Задачи отсутствуют",
        "took_on_task": "Кто-то уже взял эту задачу",
        "contact_user": "Контактное лицо",
        "not_widget": "Такого виджета не существует",
        "no_data_2": "Записи отсутствуют",
        "work_desc": "Описание работы",
        "work_type": "Тип работ",
        "date": "Дата",
        "count": "Количество",
        "unit_measurement": "Единица измерения",
        "warning": "Предупреждение",
        "item_delete_message": "Вы действительно хотите удалить эту запись?",
        "item_removed": "Запись удалена",
        "item_updated": "Запись обновлена",
        "item_added": "Запись добавлена",
        "all": "Все",
        "delivery": {
            "allPoints": "Все",
            "address": "Адрес",
            "order": "Заказ",
            "client": "Клиент",
            "phone": "Телефон",
            "email": "E-mail",
            "showOnMap": "Показать на карте",
            "routeOnMap": "Маршрут на карте",
            "editRoute": "Изменить маршрут",
            "save": "Сохранить",
            "paid_suppliers": "Оплачено поставщикам",
            "delivery_date_and_time": "Дата и время доставки",
            "fact_delivery_date": "Фактическая дата доставки",
            "pay": "Оплатить",
            "updated": "обновлен",
            "warehouseLoading": "Склад погрузки",
            "deliveryPoint": "Точка отгрузки",
            "product_succes_delivery": "Товар успешно отгружен"
        },
        "delete_message": "Вы действительно хотите удалить?",
        "problem_task": "Проблемная задача",
        "add_to_sprint": "Добавить в спринт",
        "delete_to_sprint": "Удалить из спринта",
        "task3": "задач",
        "subtask_count": "Кол-во подзадач",
        "comment_count": "Кол-во комментариев",
        "files_count": "Кол-во прикрепленных файлов",
        "card_desc": "Эта карточка с описанием",
        "in_process": "В процессе",
        "im_operator": "Делаю",
        "im_owner": "Поручил",
        "im_visor": "Наблюдаю",
        "overdue": "Просрочено",
        "overdue2": "Просрочена"
    }
}